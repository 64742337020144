<template>
<v-card flat class="mt-5">
    <v-card-text>

        <div id="app">

            <v-snackbar v-model="snackbartest.visible" auto-height :color="snackbartest.color" :multi-line="snackbartest.mode === 'multi-line'" :timeout="snackbartest.timeout" :top="snackbartest.position === 'top'">
                <v-layout align-center pr-4>
                    <v-icon class="pr-3" dark large>{{ snackbartest.icon }}</v-icon>
                    <v-layout column>
                        <div>
                            <strong>{{ snackbartest.title }}</strong>
                        </div>
                        <div>{{ snackbartest.text }}</div>
                    </v-layout>
                </v-layout>
                <v-btn v-if="snackbartest.timeout === 0" icon @click="snackbartest.visible = false">
                    <v-icon>clear</v-icon>
                </v-btn>
            </v-snackbar>

            <v-data-table :headers="headers" :items="listcible" sort-by="code" class="elevation-1">
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>LISTE Cibles ODD</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                        <v-dialog v-model="dialog" max-width="500px">

                            <v-snackbar v-model="snackbar" :multi-line="multiLine" color="primary">
                                {{ text }}

                                <template v-slot:action="{ attrs }">
                                    <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                                        Fermer
                                    </v-btn>
                                </template>
                            </v-snackbar>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="nouveau()">
                                    Nouveau
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title>
                                    <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>

                                <v-card-text>
                                    <v-container>
                                        <v-form ref="form" v-model="valid" lazy-validation>
                                            <v-select :items="listodd" v-model="selecteodd" dense item-value="id" :rules="[v => !!v || 'Veuillez selectionnez le odd']" required outlined label="Liste odd">
                                                <template slot="selection" slot-scope="data">
                                                    {{ data.item.code }} - {{ data.item.libelle }}
                                                </template>
                                                <template slot="item" slot-scope="data">
                                                    {{ data.item.code }} - {{ data.item.libelle }}
                                                </template>
                                            </v-select>
                                            <v-text-field v-model="code" label="Cible CODE" dense outlined required :rules="codeRules"></v-text-field>
                                            <v-textarea v-model="libelle" label="Description" dense outlined required :rules="libelleRules"></v-textarea>

                                        </v-form>

                                    </v-container>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="close">
                                        Fermer
                                    </v-btn>
                                    <v-btn color="blue darken-1" text @click="save">
                                        Enregistrer
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogDelete" max-width="500px">
                            <v-card>
                                <v-card-title class="text-h5">Voullez vous vraiment supprimer?</v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="dialogDelete=false">Annuller</v-btn>
                                    <v-btn color="blue darken-1" text @click="deletecible">Confirmer</v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:[`item.odds`]="{ item }">
                    {{ item.odds.code }} - {{ item.odds.libelle }}
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="editItem(item)">
                        mdi-pencil
                    </v-icon>
                    <v-icon small @click="deleteItem(item)">
                        mdi-delete
                    </v-icon>
                </template>
                <template v-slot:no-data>
                    <v-btn color="primary" @click="Actulisation">
                        Actualiser la liste
                    </v-btn>
                </template>
            </v-data-table>

        </div>

    </v-card-text>
</v-card>
</template>

<script>
import api from './../../../serviceApi/apiService'

export default {
    data: () => ({
        multiLine: true,
        snackbar: false,
        snackbars: false,
        snackbartest: false,
        textmessage: '',
        text: `message`,
        textsnackbar: 'messages',
        dialog: false,
        dialogDelete: false,
        textetat: false,
        headers: [{
                text: 'ID',
                value: 'id',
                divider: true,
                width: '10px'
            },
            {
                text: 'Code cible',
                value: 'code',
                divider: true,
                width: '10px'
            },
            {
                text: 'Libellé du cible',
                value: 'description',
                divider: true,
                width: '400px'
            },
            {
                text: 'Libelle odd',
                value: 'odds',
                divider: true,
                width: '300px'
            },
            {
                text: 'Actions',
                value: 'actions',
                sortable: false,
                divider: true,
                width: '10px'
            },
        ],
        selecteodd: '0',
        id_cibles: '0',
        listcible: [],
        listodd: [],
        listodd2: [],
        id: '0',

    }),
    methods: {
        //=====Afficher tout=======

        readAll: async function () {
            const data = await api.readAll('cibles/all');
            this.listcible = data.list;
        },
        readodd: async function () {
            const data = await api.readAll('odds/all');
            this.listodd = data.list;

        },
        deletecible: async function () {
            let fd = new FormData();
            this.id_odds = this.selecteodd;
            fd.append('id', this.id_cibles);
            const res = await api.createUpdatedata('cibles/delete/', fd);
            this.text = res.message;

            this.readAll();
            this.dialogDelete = false;
            this.clear();
            // this.snackbar=true; 
        },

        createUpdatedata: async function () {

            let fd = new FormData();
            //this.id_odds=this.selectodd;
            fd.append('id_odds', this.selecteodd);
            fd.append('code', this.code);
            fd.append('description', this.libelle);

            if (this.textetat) {
                console.log(this.selecteodd);

                const res = await api.createUpdatedata('cibles/update/' + this.id, fd);
                if (res.status == 200) {
                    this.textmessage = res.message;
                    this.snackbartest = {
                        color: "green",
                        icon: "mdi-check-all",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    };
                    this.dialog = false;

                }
            } else {
                const res = await api.createUpdatedata('cibles/add', fd);
                if (res.status == 200) {
                    this.textmessage = res.message;
                    this.snackbartest = {
                        color: "green",
                        icon: "mdi-check-all",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    };

                }
            }
            this.clear();
            this.readAll();

        },
        close() {
            this.dialog = false;
        },

        save() {
            this.createUpdatedata();

        },
        nouveau() {
            this.textetat = false;
            this.selecteodd = "0";
            this.libelle = '';
            this.code = '';
            this.dialog = true;
        },
        editItem(item) {
            this.id = item.id;

            this.libelle = item.description;
            this.code = item.code;
            this.selecteodd = item.odds.id;
            this.dialog = true;
            this.textetat = true;
        },

        deleteItem(item) {
            this.id_cibles = item.id;
            this.dialogDelete = true;

        },

        clear() {
            this.libelle = '';
            this.code = '';
            this.textetat = false;
        },

        parcoure: function filtrerParID(obj) {
            if (obj.id == this.id) {
                return true;
            } else {

                return false;
            }
        },

    },

    mounted() {
        this.readAll();
        this.readodd();

    },

}
</script>

